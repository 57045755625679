import React from 'react';
import { RedocStandalone } from 'redoc';
import { css } from '@emotion/css';
import { API_DOCS_FOLDER, axios } from 'config';
import { handleAxiosError } from 'utils';
import { useLoaderData } from 'react-router-dom';
import { colors } from 'style';
import logo from 'assets/logo.svg';

const Styles = {
  root: css({
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    display: 'block',
    background: 'white',
    'div.menu-content::before': {
      display: 'block',
      content: '" "',
      background: `url(${logo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'calc(100% - 40px) auto',
      backgroundPosition: 'center center',
      height: '2em',
      margin: '0.2em 0',
    },
  }),
};

function JsonDoc() {
  const jsonSpec = useLoaderData();

  return (
    <div className={Styles.root}>
      <RedocStandalone
        spec={jsonSpec}
        options={{
          theme: {
            colors: {
              primary: {
                main: colors.primary,
                light: colors.primaryLight,
                contrastText: colors.primaryDarker,
              },
              http: {
                get: colors.primary,
                post: colors.secondary,
              },
            },
            sidebar: {
              backgroundColor: colors.backgroundLight,
            },
          },
        }}
      />
    </div>
  );
}

async function loader({ params }) {
  const { dir, filePath } = params;
  const [version, fileName, latest] = filePath.split('~');

  try {
    const resp = await axios.get(
      `${API_DOCS_FOLDER}/${dir}${
        !latest ? '/older' : ''
      }/${version}/${fileName}`,
    );
    return resp.data;
  } catch (error) {
    handleAxiosError(error);
    return null;
  }
}

export default JsonDoc;
export { loader };
