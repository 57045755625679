const colors = {
  primary: '#FF4800',
  primaryLight: '#ffa365',
  primaryDarker: '#d53504',
  secondary: '#2D1955',
  secondaryLight: '#A096FA',
  secondaryDark: '#0A1E32',
  backgroundLight: '#f8f5f5',
  text: '#222',
};

function getHexHighlightColor(color = '#ffffff') {
  return color + 'CC';
}

export { colors, getHexHighlightColor };
